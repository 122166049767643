.containerTitleModalImage {
display: flex;
justify-content: space-between;
flex-direction: row;
width: 100%;
}

.beforeAndAfterContainer{
width: 50%;
align-items: center;
display: flex;
justify-content: center;
}


.titleModalImages{
    font-weight: bold;
    font-size: 20px;
}

.titleQuoteContainer{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 20px;
   
}