.proposalPrice{
    padding-top: 1rem ;
    flex-direction: row;
    display: flex;
    align-items: center;

    padding-left: 5rem;
}

.totalTrs{
    padding-top: 1rem ;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 26rem;
 
    /* background-color: red; */
}

.MuiTableRow-root:hover { 
    background: #dedede !important 
}

@media (max-width: 1600px) {
    .totalTrs{
  
        padding-left: 24rem;
    }
}

