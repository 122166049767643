.openModalDocumentsList{
    width: 80% !important;
    
}

@media (max-width: 768px) {
    .openModalDocumentsList{
        width: 100% !important;
    }
}


@media (max-width: 1650px) {
    .openModalDocumentsList{
        width: 95% !important;
    }
}