.sign-in-Page {
  height: 100vh;
  width: 100%;
  background-image: url("https://www.thesun.co.uk/wp-content/uploads/2018/01/nintchdbpict000380224610.jpg?strip=all&quality=100&w=1620&h=1080&crop=1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 1450px) {
  .sign-in-Page {
    height: 100%;
  }
}
