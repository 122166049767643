.bannerInterest{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding:30px;
    background-color: #1eb3e4;
    border-style: dashed;
    color: #fff;
    border-color: black;
    
}