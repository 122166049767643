
.menu-text{
    margin: 0px;
}

.nav-text-hidden{
    padding-left: 1.8rem;
}

.nav-text{
    padding-left: 0.5rem;
    color:#fff;
}
.admin-sider {
    position: fixed;
    left: 0;
    top: 60px;
    height: 90vh;
    overflow: auto;
    background: #E16726 !important;
    padding-top: 20px;  
    height: 100vh;  
    height: auto;
    z-index: 100;
  }

  .menu{
    background-color: transparent;
    color:#fff;
    font-weight: bold;
  }
  
