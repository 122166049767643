.loginForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* padding: 10px 30px; */
  background-color: #fff;
  margin-top: 10vh;
  align-items: center;
  height: 75vh;
  max-width: 420px;
  max-height: 581px;
  min-height: 500px;
  min-width: 300px;
  width: 100%;
  margin-left: 3vh;
  margin-right: 3vh;
  border-radius: 15px;
  box-shadow: 1px 9px 10px rgba(0, 0, 0, 0.37);
}

.centeredLogo {
  max-width: 271px;
  max-height: 95px;
}

.containerLogo {
  padding-top: 5vh;
}

.login-form__input {
  border: none !important;
  background-color: #fff !important;
  border-bottom: 2px solid #e16726 !important;
  border-radius: 0%;
  width: 100% !important;
  margin-top: 10px;
  color: #393939;
  font-weight: 600;
}

.loginInputLabel {
  width: 100%;
  color: #6a6a6a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
}

.containerControls {
  padding-top: 10vh;
  width: 100%;
  max-width: 350px;
  padding-left: 20px;
  padding-right: 20px;
}

.login-form__button {
  background-color: #e16726;
  color: #fff;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.37);
  margin-top: 3vh;
  /* padding-left: 5vh;
      padding-right: 5vh; */
  border-radius: 5px;
  width: 50%;
  text-transform: uppercase;
  /* font-family: "Open Sans"; */
  font-weight: 700;
  text-align: center;
  height: 6vh;
  margin-left: 10px;
  margin-right: 10px;
  letter-spacing: 0.09em;
}

.containerLoginButton {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2vh;
}

.buttonRecuperarContraseña {
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: #e16726;
  font-style: normal;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-k83k30).ant-btn-default:not(
    :disabled
  ):hover {
  color: #202020;
  border-color: #202020;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: rgba(0, 144, 158, 0.6);
  font-style: normal;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-k83k30).ant-btn-default:not(
    :disabled
  ):hover {
  color: #202020;
  border-color: #202020;
}
