.tableDocuments{
    width: 100%;
    /* margin-right: 20px !important; */
    /* font-size: 12px; */
}


  
  .listContainer {
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }


